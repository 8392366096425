import React from 'react'

export default function Support() {
    return ( 
<div id="psect13" className="card">
                        <h2 className='the-rare'><em></em><strong>Our Partners</strong></h2>
                        <div id="pcont41" className="pagecontent ">

                            <div className="bio">
                                <div className="bio-frame">

                                    <section className="bio-group">

                                        <article className="">
                                        <a href='https://www.aniseed.com'>
                                            <div className="partner-image" >
                                            </div>
                                            </a>
                                            <div className="bio-text">
                                                
                                                

                                               
                                            </div>

                                        </article>

                                        <article className="">
                                        <a href="https://www.kwiktrust.com">
                                            <img className="partner-image2"/>
                                            </a>  
                                            <div className="bio-text">
                                              
                                                

                                                
                                            </div>

                                        </article>

                                        <article className="">
                                        <a href="https://www.rainforesttrust.org/uk/">
                                            <div className="partner-image3" >
                                            
                                            </div>
                                            </a>
                                            <div className="ecolist-support">
                                                
               
                                            </div>

                                        </article>

                                        

                                    </section>


                                </div>
                            </div>

                        </div>

                        <div id="pcont93" className="pagecontent ">

                        </div>

                    </div>

    )
}