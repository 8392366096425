import React from 'react'

export default function Teams() {
    return ( 
<div id="psect13" className="card">
                        <h2 className='the-rare'>The <em></em><strong>Regen Bee/Aniseed Team</strong></h2>
                        <div id="pcont41" className="pagecontent ">

                            <div className="bio">
                                <div className="bio-frame">

                                    <section className="bio-group">

                                        <article className="bio-holder">
                                            <div className="bio-image" >
                                            </div>
                                            <div className="bio-text">
                                                <h2>Duncan Murray</h2>
                                                <h3>CEO</h3>

                                                <p>Duncan is passionate about using technology for good and has 20+ Years charity and financial services experience working for several top institutions. Duncan currently sits on the board of the Ormiston Trust, a large independent UK charity who works with the Department of Education in the UK to sponsor over 30 schools. </p>
                                            </div>

                                        </article>

                                        <article className="bio-holder">
                                            <div className="bio-image2" >
                                            </div>
                                            <div className="bio-text">
                                                <h2>Tom Brunner</h2>
                                                <h3>Partnerships</h3>

                                                <p>Tom has 20+ years digital marketing experience as a senior executive, working at companies such as Microsoft and Verizon Media. Tom sits on the board of Space for Giants, a conversation charity in Africa protecting the last remaining Elephants. </p>
                                            </div>

                                        </article>

                                        <article className="bio-holder">
                                            <div className="bio-image3" >
                                            </div>
                                            <div className="bio-text">
                                                <h2>Niall Brown</h2>
                                                <h3>CTO</h3>

                                                <p>With over 20 years of technical and business experience at leading institutions such as Lloyds. Niall has successfully worked on numerous high-profile technical products and implementations. Niall leads and oversees all technical aspects of Aniseed.</p>
                                            </div>

                                        </article>

                                        <article className="bio-holder">
                                            <div className="bio-image4" >
                                            </div>
                                            <div className="bio-text">
                                                <h2>Davin Broadbent</h2>
                                                <h3>Marketing and Social Media</h3>

                                                <p>20+ Years marketing experience as owner of a top marketing agency in the UK called Proven. Davin has extensive crypto experience as is an advisor to a number of projects and is responsible for generating awareness of Aniseed. </p>
                                            </div>

                                        </article>

                                        <article className="bio-holder">
                                            <img className="bio-image5"/>
                                            
                                            <div className="bio-text">
                                                <h2>Paul Mason</h2>
                                                <h3>COO</h3>

                                                <p>20+ Years senior management operations experience at leading blue chip financial institutions such as Wellington Asset Management. Paul has overseen many large operational departments at some of the World’s leading financial institutions and is responsible for all operational aspects of Aniseed and the Regens.</p>
                                            </div>

                                        </article>

                                        <article className="bio-holder">
                                            <div className="bio-image6" >
                                            </div>
                                            <div className="bio-text">
                                                <h2>Dean Tetelman</h2>
                                                <h3>Developer and Artist</h3>

                                                <p>Smart contract developer with a passion for art and environnmentalism.</p>
                                            </div>

                                        </article>

                                        

                                    </section>


                                </div>
                            </div>

                        </div>

                        <div id="pcont93" className="pagecontent ">

                        </div>

                    </div>

    )
}