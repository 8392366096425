import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { MySlider } from "./my-slider";
import Roadmap from "./roadmap";
import { Faqs } from "./faq";
import  Partners  from "./partners";
import { FormatAlignRight } from "@material-ui/icons";
import Teams from "./team";
import Support from "./support";











const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button">
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>
          <li>
            <img className="mobile-nav-logo" src="./logo.png" alt="" />
          </li>
          <li>
            <a href="/#link1" onClick={toggleMenu}>
              NFT
            </a>
          </li>
          <li>
            <a href="/#link2" onClick={toggleMenu}>
              About
            </a>
          </li>
          <li>
            <a href="/#link3" onClick={toggleMenu}>
              Roadmap
            </a>
          </li>
          <li>
            <a href="/#link4" onClick={toggleMenu}>
              Partners
            </a>
          </li>
          <li>
            <a href="/#link5" onClick={toggleMenu}>
              FAQ
            </a>
          </li>
          <li>
            <a href="/#link6" onClick={toggleMenu}>
              Team
            </a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/twitter.svg" alt="" />
              </a>
              <a href="https://discord.com" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/discord.svg" alt="" />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <img className="nav-logo" src="/img/logo.png" alt="" />
          <a className="hide-800" href="/#link1">
            NFT
          </a>
          <a className="hide-800" href="/#link2">
            About
          </a>
          <a className="hide-800" href="/#link3">
            Roadmap
          </a>
          <a className="hide-800" href="/#link3">
            Partners
          </a>
          <a className="hide-800" href="/#link4">
            FAQ
          </a>
          <a className="hide-800" href="/#link5">
            Team
          </a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://discord.com" target="_blank" rel="noreferrer">
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
          </div>
        </div>
      </nav>

      <div className="content-wrapper">
        <header className="card" id="link1">
          <div className="rainbow-gallery1">
            <img src="/icons/bee-logo.png" alt="" />
          </div>
          <div style={{ padding: "0 12px 0 12px 0" }}>
            <h1 className="the-rare">Join The Regens</h1>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletDialogProvider>
                    <Minter
                      candyMachineId={candyMachineId}
                      connection={connection}
                      startDate={startDateSeed}
                      txTimeout={txTimeout}
                      rpcHost={rpcHost}
                    />
                  </WalletDialogProvider>
                </WalletProvider>
              </ConnectionProvider>
            </ThemeProvider>
          </div>
        </header>

        <div>
          <MySlider />
        </div>



        <div id="psect4" className="card">
          <Partners />
        </div>



        <div id="link2" className="container-bio">
          <h1 className="bio-heading">Our Story</h1>
          <div className="bio-font">
          
          <p>

          The Regens, powered by Aniseed, is a BAYC PFP style collection designed entirely to help the planet. 
          Each Regen will give the owner unique access and benefits over top conservation projects globally. 
          The Regens will comprise members of the public, NFT holders and handpicked thought leaders, influencers, 
          policy makers and high-profile members of the conservation industry. 
          The community will help shape the future direction of the Regen Club and 
          there will be a vote once a quarter to determine the next project to be supported.        

          </p>
        </div>
        <img src="./teampics/Bee-7" alt=""/>
        </div>
        
        

        <div id="link3" className="card">
          <h1 className="roadmap-heading"></h1>
          <Roadmap />
        </div>

        <div id="link4">
          <Support/>
        </div>


        <div id="link5" className="container faq">
          <h1 className="roadmap-heading" style={{ padding: "0 0 24px 0" }}>FAQ</h1>
          <div>
            <h4>How can I participate?</h4>
            <p>
              Public sale will start from TBC, no bees will be pre minted so all 10,000 are for sale
            </p>

            <hr />
          </div>

          <div>
            <h4>How do I know 100% of the first collection will be donated to Ukraine?</h4>
            <p>
              All donations will be independently verified by our partners at Coinpass and confirmed by the NGO’s.
            </p>

            <hr />
          </div>

          <div>
            <h4>When is the drop and what is the price?</h4>
            <p>
              The public sale will be on TBC. Each Bee will cost 1 SOL (Subject to change)
            </p>

            <hr />
          </div>
          <div>
            <h4>How many Bees can I mint?</h4>
            <p>
              There is a limit of 1 Bee per transaction. There is no limit on the number of transactions.
            </p>

            <hr />
          </div>
          <div>
            <h4>Are there secondary royalties for the team?</h4>
            <p>
              A 20% Regen treasury royalty fee will be built into the smart contract to ensure that the projects are funded with every sale on the secondary market. The website is free for anyone to join but Regens holders will have access to special benefits such as trips to the projects, animal naming rights, exclusive updates, blogs, online meetings with the teams, camera traps and live streams. Bees were chosen as they are vital to the planet and each Regen sold will help directly fund the regeneration projects while all NFT’s are carbon offset via our partners Wildlife Works. The Regens aims to make supporting conservation transparent, engaging and fun and will support all biodiversity projects, with a focus on lesser-known areas such as rewilding, peatland restoration and replanting of seagrass. In addition, we will support local communities with a focus on rangers and their families. We would like to provide the NFT holder with complete transparency over all funds raised, where they are being sent and crucially what the project is going with the money. To this end we will agree various milestones and criteria with each project to receive funds from us . Eventually we would like these milestones to be inbuilt and automated in the system.          
            </p>

            <hr />
          </div>
          <div>
            <h4>What are some benefits for holders?</h4>
            <p>
              All Regen NFT holders get access to the Regen Club, a new and interactive way to support conservation. Additional NFT benefits will vary by project include but are not limited to: exclusive blog and video content, secondary species airdrop, in-project token airdrop, merch, park camera access, naming baby animals, entry to the project trip lottery.
            </p>

            <hr />
          </div>
          <div>
            <h4>What does official partner mean?</h4>
            <p>
              We work closely with several corporations and projects, and Aniseed Digital Ltd has contractual permission to use the logo of every partner listed.
            </p>

            <hr />
          </div>
        </div>


      
      
        <div id="link6">
          <Teams/>
        </div>


         { // <div className="card">
         // <h1 className="roadmap-heading">Meet the Team!</h1>
          //</div>
          //<div className="rainbow-gallery">
          //<img src="/icons/bee-6.png" alt="" />
          //<h1 className="container">Member 1</h1>
          
         // <h1 className="container">Member 2</h1>
          //<img src="/icons/bee-3.png" alt="" />
          //<h1 className="container">Member 3</h1>
         // <img src="/icons/bee-2.png" alt="" />
          //<h1 className="container">Member 4</h1>
          //<img src="/icons/bee-1.png" alt="" />
          //</div>  
         }





        <div id="mobileNavContainer" className="mobile-nav">
          <div className="mobile-nav-close-button">
            <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
          </div>
          <ul>

            <li>
              <a href="/#link1" onClick={toggleMenu}>
                NFT
              </a>
            </li>
            <li>
              <a href="/#link2" onClick={toggleMenu}>
                About
              </a>
            </li>
            <li>
              <a href="/#link3" onClick={toggleMenu}>
                Roadmap
              </a>
            </li>
            <li>
              <a href="/#link4" onClick={toggleMenu}>
                Partners
              </a>
            </li>
            <li>
              <a href="/#link5" onClick={toggleMenu}>
                FAQ
              </a>
            </li>
            <li>
              <a href="/#link6" onClick={toggleMenu}>
                Team
              </a>
            </li>
            <li>
              <div className="social-icons">
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <img className="nav-social" src="/icons/twitter.svg" alt="" />
                </a>
                <a href="https://discord.com" target="_blank" rel="noreferrer">
                  <img className="nav-social" src="/icons/discord.svg" alt="" />
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="mobile-menu-button" onClick={toggleMenu}>
          <img src="/icons/menu.svg" alt="" />
        </div>
        <nav>
          <div className="nav-container">

            <a className="hide-800" href="/#link1">
              NFT
            </a>
            <a className="hide-800" href="/#link2">
              About
            </a>
            <a className="hide-800" href="/#link3">
              Roadmap
            </a>
            <a className="hide-800" href="/#link3">
            Partners
          </a>
            <a className="hide-800" href="/#link4">
              FAQ
            </a>
            <a className="hide-800" href="/#link5">
              Team
            </a>
            <div className="social-icons hide-800">
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/twitter.svg" alt="" />
              </a>
              <a href="https://discord.com" target="_blank" rel="noreferrer">
                <img className="nav-social" src="/icons/discord.svg" alt="" />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default App;
