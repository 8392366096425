import React from 'react'

import partner1 from './img/aniseed.png';
import partner2 from './img/Kwiktrust.png';
import partner3 from './img/RTUK.png';


export default function Partners() {
    return (
<div>
<h1 className='the-rare'><em></em><strong>Projects</strong> Supported</h1>
          <div id="pcont23" className="pagecontent flexwrap">


            <div className="ecolist">
              <div className="ecolist-frame">

                
                
                <div className="ecolist-holder">
                  <a href="https://www.rhinofund.org/">
                    <div className="ecolist-image">
                      
                    </div>
                  </a>
                  <div className="ecolist-partner" >

                    <div className="ecolist-profile">
                      <h3>Rewilding Eastern Black Rhino</h3>
                      <h3>Rhino Fund Uganda</h3>
                      <h4>Uganda</h4>
                      <p>To help in the move from the UK and </p>
                      <p>protection of four Eastern Black Rhinos that are</p>
                        <p>being rewilded into Uganda for the first time in 30 years.</p>
                    </div>



                    <div className="ecolist-specs">
                      <h5>Project Details:</h5>
                      <ul>
                        <p>Our target is 71 Rangers Salaries for one year.</p>
                        <p>Regen holder benefits offered: Use of volunteer accommodation, naming a baby rhino, access to camera traps, and meeting the team!</p>
                      </ul>
                      <div className="ecolist-scale">



                      </div>
                      <div className="ecolist-support">
                        <a href="https://www.givengain.com/d/c/2878#amount">Support this Project</a>
                      </div>
                      <div className="ecolist-todetails">
                        <a href="https://www.rhinofund.org/mission-and-vision">More Information</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ecolist-holder">
                  <a href="https://rangelandsrestoration.org/">
                    <div className="ecolist-image2">
                      <div></div>
                    </div>
                  </a>
                  <div className="ecolist-partner">

                    <div className="ecolist-profile">
                      <h3>Regenerating 200,000 acres</h3>
                      <h3>Rangeland Restorations Trust</h3>
                      <h4>Beitbridge, Zimbabwe</h4>
                      <p>To help regenerate 70,000ha of rangeland habitat through holistic land management practices, and develop an independently managed
                        world-class conservation area.</p>
                     </div>



                    <div className="ecolist-specs">
                      <h5>Project Details:</h5>
                      <ul>
                        <p>Our first goal is the regeneration of 50,000 acres.</p>
                        <p>Regen benefits offered: Use of volunteer accommodation, naming a baby rhino, access to camera traps, and meet the team!</p>
                      </ul>
                      <div className="ecolist-scale">



                      </div>
                      <div className="ecolist-support">
                        <a href="https://rangelandsrestoration.org/donate">Support this Project</a>
                      </div>
                      <div className="ecolist-todetails">
                        <a href="https://rangelandsrestoration.org/about-us">More Information</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ecolist-holder">
                  <a href="https://shannonelizabeth.org/">
                    <div className="ecolist-image3">
                      <div></div>
                    </div>
                  </a>
                  <div className="ecolist-partner">

                    <div className="ecolist-profile">
                      <h3>Protection of Munu the Rhino</h3>
                      <h3>The Shannon Elizabeth Foundation</h3>
                      <h4>South Africa</h4>
                      <p> Munu now lives in a secure location where he is free and </p>
                        <p>safe to live out his days in peace and without threat. Although Munu is blind, he is healthy in all other respects. </p>
                          <p>By saving him, he will also contribute to the gene pool of this scarce rhino.</p>
                    </div>



                    <div className="ecolist-specs">
                      <h5>Project Details:</h5>
                      <ul>
                        <p>Our first target is to keep Munu fed for 12 months.</p>
                        <p>Regen benefits offered: Use of volunteer accommodation, naming a baby rhino, access to camera traps, walking safari with team. </p>
                      </ul>
                      <div className="ecolist-scale">



                      </div>
                      <div className="ecolist-support">
                        <a href="https://shannonelizabeth.org/make-a-difference/">Support this Project</a>
                      </div>
                      <div className="ecolist-todetails">
                        <a href="https://shannonelizabeth.org/our-mission/">More Information</a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          </div> 
          )
         }