import React, { useEffect, useLayoutEffect, useState } from 'react';

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slide1 from './sliders/Bee-6.png';
import slide2 from './sliders/Bee-1.png';
import slide3 from './sliders/Bee-2.png';
import slide4 from './sliders/Bee-3.png';
import slide5 from './sliders/Bee-5.png';


export const MySlider:React.FC<any> =()=> {
const [sliderSettings, setSettings] = useState<any>({});
const [size, setSize] = useState([0, 0]);
  var settings1 = {
   
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
 useEffect(() => {
   if (window.innerWidth <= 600) {
     setSettings(settings2)
   }else{
    setSettings(settings1)

   }
 }, [size])

 

 useLayoutEffect(() => {
    function updateSize() {
    setSize([window.innerWidth, window.innerHeight]);
    }
   window.addEventListener('resize', updateSize);
   updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []); 
 

    return (
        <div className='my-5 slider-container mx-0' >
        <Slider {...sliderSettings}>
          <div className='slider-item' >
            <img src={slide1} alt=""  />
          </div>
          <div className='slider-item'>
            <img src={slide2} alt=""  />
          </div>
          <div className='slider-item'>
            <img src={slide3} alt=""  />
          </div>
          <div className='slider-item'>
            <img src={slide4} alt=""  />
          </div>
          <div  className='slider-item'>
            <img src={slide5} alt=""  />
          </div>
          {/* <div className='slider-item'>
            <img src={slide2} alt="Elephant"  />
          </div> */}
        </Slider>
      </div>
    )
} 