import React from 'react'

export default function Roadmap() {
    return (
        <div>
            <div className="row">
                <div className="col-12 pl-6">
                    <div className='the-rare'>Roadmap</div>
                    <div className="rainbow-gallery2 img" style={{ padding: "0 0 24px 0" }}>
                    <img src="/icons/pie chart.png" alt=""/>
                        </div>
                </div>         
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="tree1">
                        <div className='d-flex align-items-center'>
                        
                            <div className='line1'></div>
                            <div className='line-content'>May</div>

                        </div>
                        <div className="container">
            
                            <ul>
                                <li>Announcement of partnerships with leading wildlife conservation projects
                                </li>
                                <li>
                                    Public sale! 
                                </li>

                            </ul>
                        </div>
                        

                    </div>
                    <div className="tree">
                        <div className='d-flex align-items-center'>
                            <div className='line2'></div>
                            <div className='line-content'>Q2</div>

                        </div>
                        <div className="container">
                            <ul>
                                
                                <li>
                                    Launch of Regen Club, exclusive section of website live

                                </li>
                                <li>
                                    Second collection launched
                                </li>
                                <li>
                                    First site visit of Uganda Rhino Project
                                </li>
                                
                                <li>
                                    Creation of first eco SuperNFT
                                </li>
                            </ul>
                        </div>

                        <div className='d-flex align-items-center'>
                            <div className='line3'></div>
                            <div className='line-content'>Q3</div>

                        </div>
                        <div className="container">
                            <ul>
                                <li>
                                    Gamification of Bees with leading partners
                                </li>
                                <li>
                                    First aspects of Aniseed World revealed
                                </li>
                                <li>
                                    More capital raises for wildlife conservation
                                </li>
                                
                                <li>
                                    IRL meet up for the 10 ‘Queen Bees’ in Bordeaux, France
                                </li>
                                <li>
                                    Launch of Rhino photography collection
                                </li>
                            </ul>
                        </div>

                        {/* <div className='d-flex align-items-center'>
                            <div className='line4'></div>
                            <div className='line-content'>DECEMBER</div>

                        </div>
                        <div className="ul4">
                            <ul>
                                <li>
                                    CEC Merch drop                                  
                                </li>
                                <li>                                 
                                    Can be purchased using $STAMPEDE                                  
                                </li>
                                <li>                                 
                                    Trip to South Africa for winner of our our raffle
                                   
                                </li>
                                <li>                                  
                                    Gamification in Spring
                                </li>
                            </ul>
                        </div> */}

                        <div className='d-flex align-items-center'>
                            <div className='line5'></div>
                            <div className='line-content'>Q4</div>

                        </div>
                        <div className="container">
                            <ul>
                                <li>
                                    Mutant Bees available for sale
                                </li>
                                <li>
                                    Launch of $SEED token that powers Aniseed economy
                                </li>
                                <li>
                                    Announcement of more Play To Earn Game features
                                </li>
                                <li>
                                    OG Bees begin earning $SEED through NFT staking
                                </li>
                                <li>
                                    Trip to Uganda for winner of our raffle during Q1 NFT collection
                                </li>

                            </ul>
                        </div>



                        <div className='d-flex align-items-center'>
                            <div className='line6'></div>
                            <div className='line-content'>Beyond</div>

                        </div>
                        <div className="container">
                            <ul>
                                <li>
                                    Launch of Rhino project documentary
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}